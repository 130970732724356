#text {
  border-style: groove;
  width: 50%;
  margin: auto;
  padding: 10px;
  font-family:Monospace; 
  font-size: larger;
  color: white;
  background-color: black;
}

.unshown{
  display: none;
}

.centered {
  text-align: center;
  margin: auto;
  display: block;
}

.App{
  background-color: aqua;
  top: 50%;
  width:75%;
  margin: auto;
  display: block;
}